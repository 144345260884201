import { render, staticRenderFns } from "./SlicedOverviewStats.vue?vue&type=template&id=237e4f22&"
import script from "./SlicedOverviewStats.vue?vue&type=script&lang=js&"
export * from "./SlicedOverviewStats.vue?vue&type=script&lang=js&"
import style0 from "./SlicedOverviewStats.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBanner } from 'vuetify/lib/components/VBanner';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBanner,VSelect,VSkeletonLoader,VTooltip})


/* vuetify-loader */
import installDirectives from "!../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
