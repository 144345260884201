<template>
  <v-sheet
    class="px-5 py-2 my-3"
    color="white"
  >
    <v-row class="d-flex align-center">
      <v-col
        cols="auto"
        class="py-2"
      >
        Include data from app versions:
      </v-col>
      <v-col
        cols="auto"
        class="py-0"
      >
        <v-switch
          v-model="includedVersionsComputed"
          label="v1"
          value="v1"
          inset
          color="shamrock"
          class="mt-0 pt-0"
          hide-details
        />
      </v-col>
      <v-col
        cols="auto"
        class="py-2"
      >
        <v-switch
          v-model="includedVersionsComputed"
          label="v2"
          value="v2"
          inset
          color="shamrock"
          class="mt-0 pt-0"
          hide-details
        />
      </v-col>
      <v-col
        cols="auto"
        class="py-0"
      >
        <v-radio-group
          v-model="includeProjectTypesComputed"
          row
        >
          <v-radio
            label="Sites"
            color="shamrock"
            value="sites"
          />
          <v-radio
            label="Infrastructure"
            color="shamrock"
            value="infrastructure"
          />
          <v-radio
            label="Infrastructure & Sites"
            color="shamrock"
            value="site,infrastructure"
          />
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-if="includedVersionsComputed.length === 0">
      <v-col cols="12">
        <v-alert
          type="warning"
          color="success"
          outlined
        >
          Please select at least one app version
        </v-alert>
      </v-col>
    </v-row>
    <!-- <v-row
      v-if="includedVersionsComputed.length !== 0"
      class="d-flex align-center"
    >
      <v-col
        cols="auto"
        class="py-2"
      >
        Include student projects and projects with onboarding data in contributor stats?
      </v-col>
      <v-col
        cols="auto"
        class="py-0"
      >
        <v-tooltip bottom>
          <template #activator="{ on }">
            <div v-on="includedVersionsComputed.includes('v1') ? on : undefined">
              <v-checkbox
                v-model="includeAllContributorsComputed"
                inset
                color="shamrock"
                class="mt-0 mb-1"
                hide-details
                :disabled="includedVersionsComputed.includes('v1')"
              />
            </div>
          </template>
          Excluding these projects is not available for v1 data
        </v-tooltip>
      </v-col>
    </v-row> -->
  </v-sheet>
</template>

<script>
export default {
  name: 'FilterSettings',
  props: {
    includedVersions: {
      type: Array,
      required: true
    },
    includeAllContributors: {
      type: Boolean,
      required: true
    },
    includeProjectTypes: {
      type: String,
      required: true
    }
  },
  computed: {
    includedVersionsComputed: {
      get () {
        return this.includedVersions
      },
      set (val) {
        if (val.includes('v1')) {
          this.includeAllContributorsComputed = true
        }
        this.$emit('update:includedVersions', val)
      }
    },
    includeProjectTypesComputed: {
      get () {
        return this.includeProjectTypes
      },
      set (val) {
        this.$emit('update:includeProjectTypes', val)
      }
    },
    includeAllContributorsComputed: {
      get () {
        return this.includeAllContributors
      },
      set (val) {
        this.$emit('update:includeAllContributors', val)
      }
    }
  }
}
</script>
